<template>
  <div class="profile">
    <div class="content">
      <!-- 资金 -->
      <div class="funds">
        <div class="funds-item">
          <span>{{ (user.balance / 100).toFixed(2) }}</span>
          <h4>账户余额</h4>
        </div>
        <div class="funds-item">
          <span>{{ (user.incomeToday / 100).toFixed(2) }}</span>
          <h4>昨日收益</h4>
        </div>
        <div class="funds-item">
          <span>{{ (user.income / 100).toFixed(2) }}</span>
          <h4>累计收益</h4>
        </div>
      </div>
      <div id="main"></div>
      <!-- 个人信息 -->
      <div class="user-info">
        <div class="info-item">
          <span>头像</span>
          <van-uploader
            v-model="file"
            :max-count="1"
            :upload-icon="photo"
            :after-read="afterRead"
            :preview-full-image="false"
          />
        </div>
        <div class="info-item">
          <span>性别</span>
          <!-- <input
            type="text"
            v-model="sex"
            placeholder="请输入性别"
            maxlength="1"
          /> -->
          <el-select v-model="sex" placeholder="请选择性别">
            <el-option
              v-for="item in sexData"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="info-item">
          <span>常住地址</span>
          <el-cascader
            v-model="address"
            :options="cityData"
            :props="{ expandTrigger: 'hover' }"
            @change="handleChange"
            separator=""
          ></el-cascader>
        </div>
        <div class="info-item">
          <span>邮箱号</span>
          <input
            type="email"
            v-model="email"
            placeholder="请输入邮箱号"
            autocomplete="off"
          />
        </div>
        <div class="info-item phone">
          <span>手机号</span>
          <input
            type="tel"
            v-model="phone"
            placeholder="请输入手机号"
            readonly
            autocomplete="off"
          />
          <router-link to="/vipCenter/changePhone">修改手机号</router-link>
        </div>
        <div class="info-item pwd">
          <span>登录密码</span>
          <input type="text" v-model="pwd" placeholder="请输入新密码" />
          <router-link to="/vipCenter/changeMoneyPwd">重置资金密码</router-link>
        </div>
        <div class="btn-submit">
          <button type="button" @click="onSubmit">确定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from "@constants/constants";
import { getMine } from "@network/mine";
import { update, getUserInfo, imgUpload } from "@network/setting";
import cityData from "../../../utils/citydata";
import * as echarts from "echarts";
export default {
  name: "",
  data() {
    return {
      net: BASE_URL,
      user: {},
      cityData,
      file: [{ url: "" }],
      photo: require("../../../assets/img/photo.png"),
      sex: "",
      address: [],
      email: "",
      phone: "",
      pwd: "",
      imgPath: "",
      prov: "",
      city: "",
      area: "",
      emailReg: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      sexData: [{
          value: '男',
        }, {
          value: '女',
        },
      ]
    };
  },

  created() {
    this.getMine();
    this.getUserInfo();
  },

  mounted() {},

  components: {},

  methods: {
    getUserInfo() {
      getUserInfo().then((res) => {
        console.log(res);
        this.file[0].url = this.net + res.msg.headimg;
        this.imgPath = res.msg.headimg;
        this.sex = res.msg.sex;
        this.email = res.msg.email;
        this.phone = res.msg.phone;
        this.prov = res.msg.prov;
        this.city = res.msg.city;
        this.area = res.msg.area;
        this.address = [res.msg.prov, res.msg.city, res.msg.area];
      });
    },
    //获取个人信息
    getMine() {
      getMine().then((res) => {
        console.log(res);
        if (res.code == 403) {
          this.$storage.remove("token");
          this.$message.error({ message: "请重新登录", offset: 300 });
          this.$router.replace("/home");
          return false;
        }
        this.user = res.data;
        this.init();
      });
    },

    //echarts init
    init() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("main"));
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(this.options);
    },

    //提交
    onSubmit() {
      if (this.file[0] == null) {
        this.$message.error({
          message: "请设置头像",
          offset: 300,
        });
        return false;
      } else if (this.email != "" && !this.emailReg.test(this.email)) {
        this.$message.error({
          message: "请输入正确的邮箱",
          offset: 300,
        });
        return false;
      }
      update(
        this.imgPath,
        this.sex,
        this.email,
        this.pwd,
        this.address[0],
        this.address[1],
        this.address[2]
      ).then((res) => {
        console.log(res);
        if (res.code != 0) {
          this.$message.error({
            message: res.msg,
            offset: 300,
          });
          return false;
        }

        if (res.token != "") {
          this.$storage.remove("token");
          this.$message.success({
            message: res.msg,
            offset: 300,
          });
          this.$router.replace("/login");
        } else {
          this.$message.success({
            message: res.msg,
            offset: 300,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      });
    },
    //上传图片
    afterRead(file) {
      console.log(file);
      imgUpload(file.file).then((res) => {
        console.log(res);
        this.$message({
          message: res.msg,
          offset: 300,
        });
        if (res.code != 0) {
          return false;
        } else {
          this.imgPath = res.data.path;
          console.log(this.imgPath);
        }
      });
    },
    handleChange(value) {
      console.log(value);
    },
  },

  computed: {
    proNums() {
      let that = this;
      //饼图数据
      let proNum = [
        {
          name: "余额",
          value: that.user.balance / 100,
        },
        {
          name: "入伙",
          value: that.user.invest / 100,
        },
        {
          name: "定制",
          value: that.user.investSelf / 100,
        },
      ];
      return proNum;
    },
    options() {
      //饼图设置
      let that = this;
      let option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: [
          {
            orient: "vertical",
            left: "25%",
            top: "4%",
            itemGap: 15, // 图例每项之间的间隔。
            align: "left", //文字在左
            itemWidth: 10,
            icon: "circle",
            textStyle: {
              color: "#333",
              fontFamily: "PingFang-SC-Medium, PingFang-SC",
              fontSize: 14,
              lineHeight: 20,
            },
            formatter: function (name) {
              var total = 0;
              var target;
              for (var i = 0, l = that.proNums.length; i < l; i++) {
                total += that.proNums[i].value;
                if (that.proNums[i].name == name) {
                  if (that.proNums[i].value == 0) {
                    target = 0;
                  } else {
                    target = that.proNums[i].value;
                  }
                }
              }
              if (target == 0) {
                return name + ":" + 0 + "%" + "  (" + target + ")";
              } else {
                return (
                  name +
                  ":" +
                  ((target / total) * 100).toFixed(2) +
                  "%" +
                  "  (" +
                  target +
                  ")"
                );
              }
            },
          },
          {
            orient: "vertical",
            left: "6%",
            top: "bottom",
            align: "left", //文字在左
            itemWidth: 14,
            icon: "none",
            textStyle: {
              color: "#333",
              fontFamily: "PingFang-SC-Medium, PingFang-SC",
              fontSize: 14,
              lineHeight: 34,
            },
            data: ["总资产"],
            formatter: function () {
              return `总资产：${(that.user.amount / 100).toFixed(2)}元`;
            },
          },
        ],
        series: [
          {
            name: "总资产",
            type: "pie",
            radius: "60%",
            center: ["15%", "35%"],
            label: {
              show: false,
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: that.proNums,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      return option;
    },
  },
};
</script>
<style lang='less' scoped>
@import "@assets/css/vipCenter/ChildComps/profile.less";
</style>